.container {
  width: 297mm;
  height: 215mm;
  left: 4mm;
  right:4mm;
  top: -6mm;
  /* display: grid; */
  justify-content: center;
  margin: 1%;
  padding: 1%;
  font-size: 60px;
  /* font-family: medieval,; */
  border: 10px;
  border-style: double;
  border-radius: 20px;
  border-color: gold;
}

.image {
  display: flex;
  justify-content: center;
}

.backgroundImage {
  position: absolute;
  height: 25%;
  width: 20%;
  left: 50%;
  top: 50%;
  translate: -50% 22%;
  z-index: 10;
  opacity: 20%;
}

.textarea {
  text-align: center;
  display: block;
  font-size: 30px;
}

.writearea {
  font-size: large;
  justify-content: center;
}

.signature {
  padding-bottom: 10px;

  display: flex;
  /* justify-content: space-between; */
  font-size: 24px;
  width: 100%;
}


span {
  border-bottom-style: dashed;
  border: 5px;
}